.mainContainer {
  margin-top: 5%;
}

.hide-on-desktop {
  display: none;
}

.cardsContainer {
  width: 90%;
  margin-top: 3%;
}

.cardsContainer {
  display: flex;
  width: 90%;
  margin: 20px auto;
  flex-wrap: wrap;
}

.firstCard {
  width: 23%;
  background-color: transparent;
  height: auto;
  margin: auto;
  padding: 1% 0;
  border-radius: 20px;
  margin-bottom: 2%;
  box-shadow: 0 0 19px -10px;
}

.firstImageContainer {
  width: 60%;
  text-align: center;
  padding: 20px 40px;
  background: var(--black);
  border-radius: 25px;
  color: white;
  margin: auto;
  margin-bottom: 20px;
}

.firstImage {
  width: 100%;
}

.textHeadingContainer {
  width: 90%;
  margin: auto;
  color: var(--black);
}

.firstCard hr {
  width: 90%;
  border-color: var(--black);
}

.checkPoints {
  width: 90%;
  margin: auto;
  line-height: 2;
  overflow: scroll;
  overflow-x: hidden;
  height: 220px;
  scroll-behavior: inherit;
}

.checkPoints::-webkit-scrollbar-thumb {
  background-color: var(--black);
  /* Change this to your desired color */
  border-radius: 25px;
}

.checkPoints::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Change this to your desired color */
  border-radius: 25px;
}

.checkPoints::-webkit-scrollbar {
  width: 8px;
  /* You can adjust the width */
  border-radius: 25px;
}

.buttonContainer {
  width: 90%;
  margin: auto;
}

.buttonContainer button {
  margin-top: 10%;
  padding: 2%;
  border-radius: 30px;
  border: 2px solid var(--black);
  background-color: var(--black);
  color: white;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in;
}


.buttonContainer button:hover {
  color: var(--black);
  background-color: white;
}

.buttonContainer button:nth-child(2) {
  margin-top: 10%;
  padding: 2%;
  border-radius: 30px;
  border: 2px solid var(--orange);
  background-color: transparent;
  color: var(--orange);
  margin-left: 3%;
  transition: all 0.3s ease-in;
}

.buttonContainer button:nth-child(2):hover {
  color: white;
  background-color: var(--black);
  border: 2px solid var(--black);
}

.lastlineContainer {
  width: 90%;
  margin: auto;
  margin-top: 3%;
}

/* Responsiveness */
@media (min-width: 1008px) and (max-width: 1300px) {
  .buttonContainer button {
    font-size: inherit;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .firstCard {
    width: 46%;
    height: auto;
  }

  .buttonContainer button {
    font-size: small;
  }
}

@media (min-width: 360px) and (max-width: 640px) {
  .firstCard {
    width: 80%;
    margin-bottom: 5%;
  }

  .checkPoints span {
    font-size: small;
  }

  .buttonContainer {
    display: flex;
  }

  .buttonContainer button {
    font-size: small;
  }

  .lastlineContainer p {
    font-size: small;
    margin-bottom: 5%;
  }

  .firstCard {
    height: auto;
  }
}