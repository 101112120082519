.mainContainer {
  display: flex;
  background-color: black;
  height: auto;
}

.mainHeading {
  color: white;
  font-size: x-large;
}

.mainHeading span {
  color: var(--orange);
}

.leftHandContainer {
  padding: 4%;
  width: 58%;
  margin-top: 8%;
}

.importantPoints {
  color: white;
  margin: 2% 0%;
}

.importantPoints span {
  color: var(--orange);
}

.rightArrow {
  color: var(--orange);
  font-size: x-large;
}

.checkPoints h2 {
  color: white;
  margin: 1% 2%;
}

.checkpointNumbers {
  display: flex;
  align-items: center;
}

.empowerButtons {
  margin-top: 2%;
  display: flex;
  width: 60%;

  justify-content: space-between;
}

.empowerButtons button {
  font-weight: 600;
  width: 47%;
  font-size: 14px;
  text-transform: capitalize;
  justify-content: space-between;
  padding: 3%;
  border: 2px solid white;
  color: white;
  background: linear-gradient(to right, black 0%, black 0%);
  background-size: 200% 100%;
  background-repeat: no-repeat;
  border-radius: 10px;
  transition: background-position 0.5s ease-in-out,
    background-color 0.5s ease-out;
}
.empowerButtons button:hover {
  background-position: -100% 0%;
  background-color: white;
  cursor: pointer;
  color: black;
  border: 2px solid var(--orange);
}

.empowerButtons button:nth-child(1) {
  font-weight: 600;
  width: 47%;
  font-size: 14px;
  text-transform: capitalize;
  justify-content: space-between;
  padding: 3%;
  border: 2px solid var(--orange);
  color: white;
  background: var(--orange);
  background-size: 200% 100%;
  background-repeat: no-repeat;
  border-radius: 10px;
  transition: background-position 0.5s ease-in-out,
    background-color 0.5s ease-out;
}
.empowerButtons button:nth-child(1):hover {
  background-position: -100% 0%;
  background-color: white;
  cursor: pointer;
  color: black;
}
.rightHandContainer {
  margin-top: 10%;
  width: 28%;
  color: white;
  border-radius: 5px;
  border: 1px solid white;
  background-color: white;
  box-shadow: 0 0 10px 10px #ffffff52;
  margin-bottom: 8%;
}

.formHeadings {
  text-align: center;
  padding: 10%;
}

.formHeadings h1 {
  margin-top: 5%;
  color: #141232;
}

.formHeadings h3 {
  margin-top: 5%;
  color: var(--orange);
}

form input {
  width: 90%;
  padding: 4%;
  color: black;
  margin-bottom: 7%;
  border: 1px solid #cacaca;
  transition: 0.5s linear ease-in;
}

form {
  margin: auto;
  width: 80%;
}

form textarea {
  width: 90%;
  padding: 4%;
  color: black;
  border: 1px solid #cacaca;
  outline: none;
  margin-bottom: 7%;
}

form textarea::placeholder,
form input::placeholder {
  font-weight: bold;
  color: #cacaca;
}

form input:active {
  outline: 2px solid black;
}

form button {
  padding: 5%;
  width: 90%;
  border-radius: 25px;
  border: 1px solid var(--black);
  background-color: var(--black);
  color: white;
  font-weight: 550;
  font-size: large;
  margin-left: 5%;
  margin-bottom: 17%;
  transition: background-color 0.3s ease-in;
}
form button:hover {
  background-color: var(--orange);
  border: 1px solid var(--orange);
  cursor: pointer;
}
/* Responsiveness  */
@media (min-width: 1008px) and (max-width: 1300px) {
  .rightHandContainer {
    width: 30%;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .mainHeading {
    font-size: large;
  }

  .importantPoints h2 {
    font-size: large;
    line-height: 2;
  }

  .checkPoints {
    font-size: x-small;
    line-height: 2;
  }

  .leftHandContainer {
    width: 55%;
  }

  .rightHandContainer {
    width: 32%;
  }

  .empowerButtons {
    width: 75%;
    margin-top: 5%;
  }

  .formHeadings H1 {
    font-size: x-large;
  }

  form button {
    font-size: small;
  }
}

@media (min-width: 360px) and (max-width: 640px) {
  .mainContainer {
    flex-direction: column;
  }

  .leftHandContainer {
    width: 90%;
    margin: auto;
  }

  .mainHeading {
    width: 80%;
    font-size: large;
    margin: auto;
    margin-top: 20%;
  }

  .importantPoints {
    width: 80%;
    font-size: x-small;
    margin: auto;
    margin-top: 5%;
    font-weight: 400;
  }

  .checkPoints {
    font-size: x-small;
    width: 80%;
    margin: auto;
    margin-top: 5%;
  }

  .empowerButtons {
    margin: auto;
    margin-top: 5%;
    display: flex;
    width: 80%;
    justify-content: space-between;
  }
  .importantPoints h2 {
    font-weight: 400;
  }
  .checkpointNumbers h2 {
    font-weight: 400;
  }
  .rightHandContainer {
    width: 80%;
    margin: auto;
    margin-bottom: 10%;
    margin-top: 10%;
  }
}
