.mainContainer {
  width: 90%;
  margin: auto;
  margin-top: 10%;
  display: flex;
}

.leftHandContainer {
  width: 40%;
}
.mainHeading {
  font-size: x-large;
}
.mainHeading h1 {
  color: var(--black);
}
.mainHeading span {
  color: var(--orange);
}
.mainHeading p {
  font-size: large;
  color: var(--black);
}

.imageContainer {
  display: flex;
  align-items: center;
}
.rightHandContainer {
  width: 35%;
  margin-left: 3%;
  margin-top: 1%;
}

.imageContainer h2 {
  margin-left: 3%;
}
.image {
  width: 12%;
}
.imageContainer .leftCircle {
  margin-left: 6%;
  font-size: 50px;
  cursor: pointer;
}
.imageContainer .rightCircle {
  font-size: 50px;
  cursor: pointer;
}
.chatContainer {
  margin-top: 8%;
  border-radius: 10px;
  box-shadow: 0 0 20px -10px;
  padding: 4%;
}
.commaImageContainer {
  width: 10%;
  margin-top: -8%;
}
.commaImage {
  width: 100%;
}
.textContainer {
  margin-top: 3%;
  color: var(--black);
}
.imageContainer {
  width: 30%;
}
.imageContainer {
  width: 100%;
}
.officeImageContainer {
  width: 20%;
}
.officeImage {
  width: 100%;
}
/* Responsiveness */
@media (min-width: 1008px) and (max-width: 1300px) {
  .imageContainer {
    width: 120%;
  }
}
@media (min-width: 641px) and (max-width: 1007px) {
  .mainHeading {
    font-size: large;
  }
  .mainHeading p {
    font-size: small;
  }
  .imageContainer h2 {
    font-size: large;
  }
  .textContainer p {
    font-size: small;
  }
}
@media (min-width: 360px) and (max-width: 640px) {
  .mainContainer {
    width: 80%;
    margin: auto;
    flex-wrap: wrap;
    margin-top: 15%;
  }
  .mainHeading h1 {
    font-size: x-large;
  }
  .leftHandContainer {
    width: 80%;
    margin: auto;
  }
  .mainHeading p {
    margin-top: 5%;
    font-size: small;
  }
  .rightHandContainer {
    width: 80%;
    margin: auto;
    margin-top: 5%;
  }
  .officeImageContainer {
    display: none;
  }
  .imageContainer h2 {
    font-size: small;
  }
  .imageContainer img {
    width: 17%;
  }
  .imageContainer .leftCircle,
  .imageContainer .rightCircle {
    font-size: xx-large;
  }
  .imageContainer {
    width: 100%;
    margin: auto;
  }
  .textContainer p {
    font-size: small;
  }
}
