.members {
  width: 80%;
  margin: auto;
}

.members h1 {
  font-size: 50px;
  text-align: center;
}

.agents {
  width: 100%;
  margin: 40px 0;
  display: flex;
  flex-wrap: wrap;
}

.item {
  width: calc(100% * (1/3));
  padding: 20px;
  text-align: center;
  margin: auto;
}

.name {
  font-size: 20px;
}

.avatar {
  width: 80px;
  border-radius: 50px;
}