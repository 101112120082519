.mainContainer {
  background: var(--black);
  display: flex;
  margin-top: 10%;
  height: 250px;
}
.leftHandContainer {
  color: white;
  width: 25%;
  padding: 5%;
}
.leftHandContainer p {
  line-height: 2;
  font-size: small;
  margin-top: 2%;
}
.rightHandContainer {
  padding: 5%;
  display: flex;
  width: 60%;
}
.chatImageContainer {
  margin-top: 5%;
}

.imageTextContaier {
  margin-top: 4%;
  text-align: right;
  margin-left: 5%;
  color: white;
}
.buttonContainer {
  width: 50%;
  margin-top: 6%;
  margin-left: 10%;
}
.buttonContainer button {
  width: 40%;
  font-size: large;
  padding: 5%;
  color: white;
  background-color: var(--orange);
  border: 2px solid var(--orange);
  border-radius: 10px;
  justify-content: space-between;
  cursor: pointer;
}

.buttonContainer button:nth-child(2) {
  width: 50%;
  padding: 5%;
  border-radius: 10px;
  border: 2px solid white;
  margin-left: 5%;
  background: transparent;
  justify-content: space-between;
}
/* responsiveness */
@media (min-width: 1008px) and (max-width: 1300px) {
  .imageTextContaier {
    width: 30%;
  }
}
@media (min-width: 641px) and (max-width: 1007px) {
  .chatImageContainer {
    width: 30%;
  }
  .chatImageContainer img {
    width: 100%;
  }
  .imageTextContaier p {
    font-size: small;
  }
  .imageTextContaier h2,
  .imageTextContaier h1 {
    font-size: x-large;
  }
  .buttonContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
  }
  .buttonContainer button,
  .buttonContainer button:nth-child(2) {
    width: 100%;
    margin-left: 0%;
  }
  .buttonContainer button:nth-child(2) {
    margin-top: 15%;
  }
}
@media (min-width: 360px) and (max-width: 640px) {
  .mainContainer {
    display: none;
  }
}
