:root {
  --orange: #ea2c0a;
  --black: #1e1e1e;
}

::-webkit-scrollbar-track {
  background-color: var(--black);
}

::-webkit-scrollbar-thumb {
  background-color: var(--black);
}

html {
  scroll-behavior: smooth;
}

body,
p,
h1,
h2,
h3,
h4,
input,
span,
textarea {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Poppins", sans-serif;
  color: inherit;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}